<template>
  <v-container>
    <!-- Formulario crear/editar sucursal -->
    <v-row>
      <div class="mt-5 px-5">
        <v-col cols="12" md="4" class="d-flex">
          <p class="ma-0 text-start">
            Administra las sucursales y almacenes de tu cadena
          </p>
        </v-col>
        <v-form v-model="validForm" ref="branchForm">
          <v-col cols="12" md="4" class="d-flex">
            <v-card
              class="pa-5 bgcards rounded-xl"
              elevation="2"
              style="width: 100%"
            >
              <v-card-text class="text-start">
                <div>
                  <span class="textlabel--text font-weight-bold"
                    >¿Qué es esto?</span
                  >
                  <v-radio-group v-model="branch.businessCategory" class="mb-1">
                    <v-radio
                      label="Sucursal (Hay venta al público)"
                      value="venta"
                      color="purple"
                    />
                    <v-radio
                      label="Almacén (Solo se almacena producto)"
                      value="almacen"
                      color="purple"
                    />
                  </v-radio-group>

                  <pat-input
                    v-model="branch.name"
                    label="Nombre de la sucursal"
                    placeholder="La Divina"
                    class="mb-4"
                    :rules="[rules.required]"
                  />

                  <p class="text-body-1 font-weight-bold">Dirección</p>
                  <pat-input
                    v-model="branch.street"
                    label="Calle"
                    placeholder="Av. Principal"
                    class="my-3"
                    :rules="[rules.required]"
                    outlined
                  />

                  <p class="text-body-1 mt-3 font-weight-bold">Teléfono</p>
                  <pat-input
                    label="Teléfono de la sucursal"
                    placeholder="55 5555 5555"
                    class="mb-3"
                    v-model="branch.PhoneNumber"
                    :maxlength="10"
                    :isNumber="true"
                    :rules="[rules.required]"
                  />

                  <div v-if="isEditing">
                    <p class="text-body-1 mt-3 font-weight-bold mb-2">
                      Empleados asociados:
                    </p>
                    <ul>
                      <li
                        v-for="(employee, empIndex) in branch.employees"
                        :key="empIndex"
                      >
                        <strong>{{ employee.name }}</strong> -
                        {{ employee.position }}
                      </li>
                    </ul>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>

          <!-- Botón Crear/Editar Sucursal -->
          <v-col
            cols="12"
            md="4"
            class="d-flex flex-column items-center justify-center"
          >
            <v-btn
              :disabled="!validForm"
              class="py-6 text-none text-body-1"
              :color="validForm ? 'primary' : 'grey darken-2'"
              block
              rounded
              @click="handleSubmit"
            >
              {{ isEditing ? "Guardar cambios" : "Crear sucursal" }}
            </v-btn>

            <v-btn
              v-if="isEditing"
              text
              class="font-weight-bold primary--text text-none text-body-1 my-4 text-decoration-underline"
              @click="deleteBranch"
            >
              Eliminar sucursal
            </v-btn>
          </v-col>
        </v-form>
      </div>
    </v-row>

    <!-- Modal advertencia de cambios perdidos -->
    <div class="text-center">
      <v-dialog
        v-model="dialogBack"
        max-width="400"
        persistent
        overlay-class="custom-dialog-overlay"
        content-class="custom-dialog-content"
      >
        <div class="position-relative">
          <!-- Tarjeta principal del contenido -->
          <v-card
            class="rounded-xl pa-6"
            style="
              background-color: #f4f4fa;
              position: relative;
              overflow: visible;
            "
          >
            <!-- Botón de cierre flotante -->
            <v-btn
              outlined
              color="primary"
              class="py-4 border-3"
              icon
              style="
                position: absolute;
                top: -40px;
                right: 0px;
                border-radius: 50%;
                border-width: 3px;
                background-color: #fff;
              "
              @click="closeDialogBack"
            >
              <v-icon color="primary ">mdi-close</v-icon>
            </v-btn>

            <v-card-title
              class="text-h5 font-weight-bold text-center"
              style="
                white-space: normal;
                overflow-wrap: normal;
                word-break: normal;
              "
            >
              ¿Estas seguro que quieres regresar?
            </v-card-title>

            <v-card-text class="text-center text-body-1 black--text"
              >Si regresas sin guardar los cambios, se perderan.</v-card-text
            >
            <v-card-actions
              class="d-flex flex-column items-center justify-center"
            >
              <v-btn
                rounded
                block
                outlined
                color="primary"
                class="py-6 border-3 text-body-1 font-weight-bold text-none mb-5"
                style="border-width: 3px"
                @click="confirmDiscardChanges"
              >
                Regresar y perder cambios
              </v-btn>
              <v-btn
                @click="continueEditing"
                class="py-6 text-none text-body-1 font-weight-bold"
                color="grey primary"
                dark
                block
                rounded
                >{{ isEditing ? "Seguir editando" : "Seguir agregando" }}</v-btn
              >
            </v-card-actions>
          </v-card>
        </div>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
import {
  createSucursal,
  updateSucursal,
  getSucursal,
  deleteSucursal,
} from "../services/auth";
export default {
  data() {
    return {
      validForm: false,
      isEditing: false,
      dialogBack: false,
      branch: {
        businessCategory: "",
        name: "",
        street: "",
        PhoneNumber: "",
      },
      isFinshed: false,
      rules: {
        required: (value) => !!value || "Este campo es obligatorio.",
      },
    };
  },
  computed: {
    phoneRules() {
      return [
        (v) => !!v || "Este campo es obligatorio.",
        (v) => /^\d{10}$/.test(v) || "El número celular debe tener 10 dígitos",
      ];
    },
  },
  methods: {
    async initBranchData() {
      if (this.isEditing) {
        const response = await getSucursal(this.$route.params.id);
        if (response.success) {
          this.branch = {
            name: response.sucursal.nombre,
            street: response.sucursal.calle,
            PhoneNumber: response.sucursal.telefono,
            businessCategory: response.sucursal.tipo_sucursal,
            employees: [],
          };
        } else {
          this.$toast({
            message: "Ocurrió un error al obtener la sucursal",
            color: "error",
            timeout: 2000,
          });
          this.isFinshed = true;
          this.$router.push({ name: "branches" });
        }
      }
    },
    handleSubmit() {
      if (!this.$refs.branchForm.validate()) {
        console.log("Formulario inválido");
        return;
      }

      if (this.isEditing) {
        this.updateBranch();
      } else {
        this.createBranch();
      }
    },
    async createBranch() {
      const response = await createSucursal(
        this.branch.name,
        this.branch.street,
        this.branch.PhoneNumber,
        this.branch.businessCategory
      );
      if (response.success) {
        this.isFinshed = true;
        this.$toast({
          message: "Sucursal creada correctamente",
          color: "success",
          timeout: 2000,
        });
        this.$router.push({ name: "branches" });
      } else {
        this.$toast({
          message: "Ocurrió un error al crear la sucursal",
          color: "error",
          timeout: 2000,
        });
      }
      console.log("Sucursal creada:", response);
    },
    async updateBranch() {
      const response = await updateSucursal(
        this.$route.params.id,
        this.branch.name,
        this.branch.street,
        this.branch.PhoneNumber,
        this.branch.businessCategory
      );
      if (response.success) {
        this.isFinshed = true;
        this.$toast({
          message: "Sucursal actualizada correctamente",
          color: "success",
          timeout: 2000,
        });
        this.$router.push({ name: "branches" });
      } else {
        this.$toast({
          message: "Ocurrió un error al actualizar la sucursal",
          color: "error",
          timeout: 2000,
        });
      }
    },
    async deleteBranch() {
      const response = await deleteSucursal(this.$route.params.id);
      if (response.success) {
        this.isFinshed = true;
        this.$toast({
          message: "Sucursal eliminada correctamente",
          color: "success",
          timeout: 2000,
        });
        this.$router.push({ name: "branches" });
      } else {
        this.$toast({
          message: "Ocurrió un error al eliminar la sucursal",
          color: "error",
          timeout: 2000,
        });
      }
    },
    closeDialogBack() {
      this.dialogBack = false;
    },
    continueEditing() {
      this.closeDialogBack();
    },
    confirmDiscardChanges() {
      this.$router.push({ name: "branches" });
    },
  },
  mounted() {
    this.isEditing = this.$route.name === "edit-branch";
    this.initBranchData();
  },
  beforeRouteLeave(to, from, next) {
    // Intercepta la navegación
    if (this.isFinshed) {
      next();
    }
    if (!this.dialogBack) {
      this.dialogBack = true;
    } else {
      next();
    }
  },
};
</script>
<style>
/* Deshabilita el overflow del modal */
.custom-dialog-content {
  overflow: visible !important;
}
</style>
